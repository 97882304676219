import type { DialogProps as AriaDialogProps } from 'react-aria-components';
import type { OverlayTriggerProps, OverlayTriggerState } from 'react-stately';
import { merge } from 'lodash-es';
import React from 'react';
import {
  Dialog as UnstyledDialog,
  Modal as UnstyledModal,
  ModalOverlay as UnstyledModalOverlay,
  OverlayTriggerStateContext,
} from 'react-aria-components';
import { useOverlayTriggerState } from 'react-stately';

import type { HeightTypes } from '../../utilities/shared/Height';
import type { MaxHeightTypes } from '../../utilities/shared/MaxHeight';
import type { MaxWidthTypes } from '../../utilities/shared/MaxWidth';
import type { MinHeightTypes } from '../../utilities/shared/MinHeight';
import type { MinWidthTypes } from '../../utilities/shared/MinWidth';
import type { WidthTypes } from '../../utilities/shared/Width';
import type {
  ShellContentProps,
  ShellFooterProps,
  ShellHeaderProps,
  ShellProps,
} from '../Shell/Shell';
import { fade, palette } from '../../common/colors';
import { zIndexes } from '../../common/z_indexes';
import { colors, darkThemeSelector, keyframes, shadows, styled } from '../../stitches.config';
import { heightCSS } from '../../utilities/shared/Height';
import { maxHeightCSS } from '../../utilities/shared/MaxHeight';
import { maxWidthCSS } from '../../utilities/shared/MaxWidth';
import { minHeightCSS } from '../../utilities/shared/MinHeight';
import { minWidthCSS } from '../../utilities/shared/MinWidth';
import { widthCSS } from '../../utilities/shared/Width';
import { Shell, ShellContent, ShellFooter, ShellHeader } from '../Shell/Shell';

export type { OverlayTriggerState };

// A hook for creating controlled dialogs
export function useDialogState(props?: OverlayTriggerProps) {
  const state = useOverlayTriggerState(props ?? {});

  // in menus, e.g. a Radix dropdown menu, the menu component will return focus to the trigger
  // when it closes, which cancels out the intended focus behavior when a Dialog opens.
  // To prevent clobbering the focus behavior, we wait until the next tick to open the dialog.
  const openFromMenu = React.useCallback(
    (event?: Event, delay = 50) => {
      setTimeout(state.open, delay);
    },
    [state],
  );

  return { state, openFromMenu };
}

export function useDialogStateCallback(props?: OverlayTriggerProps) {
  const { state, openFromMenu: baseOpenFromMenu } = useDialogState(props);
  const openFromMenu = React.useCallback(() => baseOpenFromMenu(), [baseOpenFromMenu]);
  return {
    state,
    openFromMenu,
  };
}

const overlayShow = keyframes({
  from: { opacity: 0 },
  to: { opacity: 1 },
});

const contentShow = keyframes({
  from: { opacity: 0, transform: 'translateY(-2%) scale(.96)' },
  to: { opacity: 1, transform: 'translateY(0) scale(1)' },
});

export const ModalOverlay = styled(UnstyledModalOverlay, {
  position: 'fixed',
  inset: 0,
  zIndex: zIndexes.dialogWash,
  backgroundColor: fade(palette.gray900, 0.1),
  backdropFilter: 'blur(5px)',
  boxShadow: `inset 0px 80px 240px 240px ${fade(palette.gray900, 0.12)}`,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  [darkThemeSelector]: {
    backgroundColor: fade(palette.gray900, 0.3),
  },

  '&[data-entering]': {
    animation: `${overlayShow} 250ms cubic-bezier(0.16, 1, 0.3, 1) forwards`,
  },

  '&[data-exiting]': {
    animation: `${overlayShow} 250ms reverse ease-in`,
  },

  variants: {
    internal: {
      true: {
        backgroundColor: fade(palette.internalBgLight, 1),
        boxShadow: `inset 0px 80px 240px 240px ${fade(palette.internalHeadingLight, 0.12)}`,

        [darkThemeSelector]: {
          backgroundColor: fade(palette.internalBgDark, 1),
          boxShadow: `inset 0px 80px 240px 240px ${fade(palette.internalHeadingDark, 0.12)}`,
        },
      },
    },
  },
});

const Modal = styled(UnstyledModal, {
  willChange: 'transform',

  '&:focus': { outline: 'none' },

  '&[data-entering]': {
    animation: `${contentShow} 250ms cubic-bezier(0.16, 1, 0.3, 1) forwards`,
  },

  '&[data-exiting]': {
    animation: `${contentShow} 250ms reverse ease-in`,
  },
});

const DialogHeaderContainer = styled(ShellHeader);

export type DialogHeaderProps = ShellHeaderProps;

export function DialogHeader({ ...remaining }: DialogHeaderProps) {
  const state = React.useContext(OverlayTriggerStateContext);

  return (
    <DialogHeaderContainer
      onClose={state?.close}
      closeButtonAriaLabel="Close dialog"
      {...remaining}
    />
  );
}

const DialogContentContainer = styled(ShellContent);

export type DialogContentProps = ShellContentProps & {
  width?: string;
  maxWidth?: string;
  height?: string;
  maxHeight?: string;
};

export function DialogContent({
  children,
  width,
  maxWidth,
  height,
  maxHeight,
  ...remaining
}: DialogContentProps) {
  return (
    <DialogContentContainer {...remaining} style={{ width, maxWidth, height, maxHeight }}>
      {children}
    </DialogContentContainer>
  );
}

const DialogFooterContainer = styled(ShellFooter);

export type DialogFooterProps = {
  /**
   * Provide any actions for your drawer.
   */
  actions?: React.ReactNode;
};

export function DialogFooter({ actions, ...remaining }: DialogFooterProps & ShellFooterProps) {
  return <DialogFooterContainer end={actions} {...remaining} />;
}

const DialogShellContainer = styled(Shell, {
  height: 'auto',
  padding: '$8 0',
  overflow: 'hidden',
  backgroundColor: colors.bgApplicationLight,
  boxShadow: shadows.modalLight,
  borderRadius: '$12',

  [darkThemeSelector]: {
    backgroundColor: colors.bgApplicationDark,
    boxShadow: shadows.modalDark,
  },
});

type DialogShellProps = ShellProps & {
  children: React.ReactNode;
};

function DialogShell({ children, contentMode, ...remaining }: DialogShellProps) {
  return (
    <DialogShellContainer contentMode={contentMode} {...remaining}>
      {children}
    </DialogShellContainer>
  );
}

const DialogContainer = styled(UnstyledDialog, {
  zIndex: zIndexes.dialogContent,
  display: 'flex',
  outline: 'none',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',

  '@mobile': {
    width: '90vw',
    minWidth: '$300 !important',
    maxWidth: '90vw !important',
    maxHeight: '90vh !important',
  },

  '@notMobile': {
    width: '90vw',
    maxWidth: '$660',
    maxHeight: '85vh',
  },

  '&:focus': { outline: 'none' },

  variants: {
    preset: {
      narrow: {},
      intermediate: {},
      wide: {},
    },
  },
});

type DialogProps = AriaDialogProps &
  Omit<ShellProps, 'width' | 'maxWidth' | 'height' | 'maxHeight'> & {
    /**
     * Select a width preset.
     */
    preset?: 'narrow' | 'intermediate' | 'wide';
    /**
     * Set whether the dialog is internal.
     */
    internal?: boolean;
    /**
     * Set whether the dialog can be dismissed by clicking outside of it.
     * @default true
     */
    isDismissable?: boolean;
    /**
     * Pass the `state` object returned from `useDialogState`.
     */
    state: OverlayTriggerState;
    /**
     * Manually set the dialog width.
     */
    width?: WidthTypes;
    /**
     * Manually set the dialog min width.
     */
    minWidth?: MinWidthTypes;
    /**
     * Manually set the dialog max width.
     */
    maxWidth?: MaxWidthTypes;
    /**
     * Manually set the dialog height.
     */
    height?: HeightTypes;
    /**
     * Manually set the dialog min height.
     */
    minHeight?: MinHeightTypes;
    /**
     * Manually set the dialog max height.
     */
    maxHeight?: MaxHeightTypes;
  };

export function Dialog({
  children,
  contentMode,
  preset,
  internal,
  width,
  minWidth,
  maxWidth,
  height,
  minHeight,
  maxHeight,
  state,
  isDismissable = true,
  ...props
}: DialogProps) {
  let presetMaxWidth;
  if (preset === 'narrow') {
    presetMaxWidth = '$400';
  } else if (preset === 'intermediate') {
    presetMaxWidth = '$800';
  } else if (preset === 'wide') {
    presetMaxWidth = '$1200';
  }
  const css = merge(
    widthCSS(width),
    minWidthCSS(minWidth),
    maxWidthCSS(preset ? presetMaxWidth : maxWidth),
    heightCSS(height),
    minHeightCSS(minHeight),
    maxHeightCSS(maxHeight),
  );

  return (
    <ModalOverlay
      isDismissable={isDismissable}
      isOpen={state.isOpen}
      onOpenChange={state.setOpen}
      internal={internal}
    >
      <Modal isDismissable={isDismissable} isOpen={state.isOpen} onOpenChange={state.setOpen}>
        <DialogContainer preset={preset} css={css} {...props}>
          <DialogShell contentMode={contentMode}>{children}</DialogShell>
        </DialogContainer>
      </Modal>
    </ModalOverlay>
  );
}
