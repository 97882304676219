import type { AriaRadioGroupProps } from '@react-types/radio';
import React, { forwardRef, useRef } from 'react';
import { useRadioGroup } from 'react-aria';
import { useRadioGroupState } from 'react-stately';

import type { IconName } from '../../assets/Icon/Icon';
import type { VariantProp } from '../../common/colors';
import type { Simplify } from '../../types/simplify';
import { Icon } from '../../assets/Icon/Icon';
import { getVariantStyles, variantTokenBgColor } from '../../common/variant';
import { Tooltip } from '../../components/Tooltip/Tooltip';
import { colors, darkThemeSelector, shadows, styled } from '../../stitches.config';
import { BodySansSizes } from '../../text/Body';
import { SmallSansSizes } from '../../text/Small';
import { Text } from '../../text/Text';
import { selectors, transitions } from '../shared/styles';

type MultiToggleInputSizeProp = 'small' | 'large';

const multiToggleInputOptionSize = {
  small: {
    height: '$16',
    aspectRatio: '1 / 1',
  },
  large: {
    height: '$20',
    aspectRatio: '1 / 1',
  },
};

const MultiToggleInputTabIcon = styled(Icon);

const MultiToggleInputTabTack = styled('div', {
  display: 'flex',
  backgroundColor: variantTokenBgColor,
  borderRadius: '99em',

  variants: {
    size: {
      small: {
        width: '$8',
        height: '$8',
      },
      large: {
        width: '$10',
        height: '$10',
      },
    },
    variant: {
      alternative: {
        ...getVariantStyles('alternative', false),
      },
      attention: {
        ...getVariantStyles('attention', false),
      },
      brand: {
        ...getVariantStyles('brand', false),
      },
      negative: {
        ...getVariantStyles('negative', false),
      },
      neutral: {
        ...getVariantStyles('neutral', false),
      },
      positive: {
        ...getVariantStyles('positive', false),
      },
    },
  },
});

type MultiToggleInputTabContainerProps = {
  size?: MultiToggleInputSizeProp;
} & React.HTMLAttributes<HTMLDivElement>;

const MultiToggleInputTabContainer = styled('div', {
  position: 'absolute',
  zIndex: 2,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '99em',
  backgroundColor: colors.white,
  boxShadow: shadows.controlRaisedInitialLight,
  pointerEvents: 'none',
  transition: transitions.control,

  [selectors.hover]: {
    boxShadow: shadows.controlRaisedHoveredLight,
  },

  [darkThemeSelector]: {
    backgroundColor: colors.gray800,
    boxShadow: shadows.controlRaisedInitialDark,

    [selectors.hover]: {
      boxShadow: shadows.controlRaisedHoveredDark,
    },
  },

  variants: {
    size: {
      ...multiToggleInputOptionSize,
    },
  },
}) as React.ComponentType<MultiToggleInputTabContainerProps>;

type MultiToggleInputTabProps = {
  /**
   * The value of the option
   */
  icon?: IconName;
  /**
   * The size of the input
   */
  size?: MultiToggleInputSizeProp;
  /**
   * The variant of the icon
   */
  variant?: VariantProp;
} & Pick<React.HTMLAttributes<HTMLDivElement>, 'style' | 'className' | 'id'>;

function MultiToggleInputTab({
  icon,
  size = 'large',
  variant = 'neutral',
  ...remaining
}: MultiToggleInputTabProps) {
  return (
    <MultiToggleInputTabContainer aria-hidden="true" size={size} {...remaining}>
      {icon ? (
        <MultiToggleInputTabIcon icon={icon} size={size === 'small' ? 10 : 12} variant={variant} />
      ) : (
        <MultiToggleInputTabTack size={size} variant={variant} />
      )}
    </MultiToggleInputTabContainer>
  );
}

const MultiToggleInputOptionLabel = styled(Text, {
  userSelect: 'none',
  variants: {
    size: {
      small: {
        fontSize: '$12',
        lineHeight: '$16',
      },
      large: {
        fontSize: '$14',
        lineHeight: '$20',
      },
    },
  },
});

const MultiToggleInputOptionTack = styled('div', {
  backgroundColor: colors.gray200,
  borderRadius: '99em',

  [darkThemeSelector]: {
    backgroundColor: colors.gray600,
  },

  variants: {
    size: {
      small: {
        heightAll: '$8',
        aspectRatio: '1 / 1',
      },
      large: {
        heightAll: '$10',
        aspectRatio: '1 / 1',
      },
    },
  },
});

type MultiToggleInputOptionContainerProps = {
  size?: MultiToggleInputSizeProp;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

const MultiToggleInputOptionContainer = styled('button', {
  position: 'relative',
  zIndex: 1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  variants: {
    disabled: {
      true: {
        opacity: 0.5,
        cursor: 'not-allowed',
      },
      false: {},
    },
    size: {
      ...multiToggleInputOptionSize,
    },
  },
}) as React.ComponentType<MultiToggleInputOptionContainerProps>;

type MultiToggleInputOptionProps = {
  /**
   * Set if the option is disabled
   */
  disabled?: boolean;
  /**
   * The label to display for the option
   */
  label: React.ReactNode;
  /**
   * Optional icon to display for the option
   */
  icon?: IconName;
  /**
   * The size of the option
   */
  size?: MultiToggleInputSizeProp;
  /**
   * The value of the option
   */
  value: string;
  /**
   * The color variant for the option
   */
  variant?: VariantProp;
  /**
   * Callback when the option is clicked
   */
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
} & Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'onClick'>;

function MultiToggleInputOption({
  disabled,
  icon,
  label,
  size,
  value,
  onClick,
  ...remaining
}: MultiToggleInputOptionProps) {
  return (
    <Tooltip
      delay={500}
      contents={
        <MultiToggleInputOptionLabel size={size} weight="bold">
          {label}
        </MultiToggleInputOptionLabel>
      }
    >
      <MultiToggleInputOptionContainer
        size={size}
        disabled={disabled}
        onClick={onClick}
        {...remaining}
      >
        <MultiToggleInputOptionTack size={size} />
      </MultiToggleInputOptionContainer>
    </Tooltip>
  );
}

const MultiToggleInputTrack = styled('div', {
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  backgroundColor: colors.gray100,
  borderRadius: '99em',
  cursor: 'pointer',
  transition: transitions.control,

  [selectors.focus]: {
    boxShadow: shadows.focusRingLight,
  },

  [darkThemeSelector]: {
    backgroundColor: colors.gray700,

    [selectors.focus]: {
      boxShadow: shadows.focusRingDark,
    },
  },

  variants: {
    disabled: {
      true: {
        opacity: 0.5,
        cursor: 'not-allowed',
      },
      false: {},
    },
    size: {
      small: {
        height: '$16',
      },
      large: {
        height: '$20',
      },
    },
  },
});

const MultiToggleInputEnds = styled(Text, {
  fontWeight: '$bold',
  whiteSpace: 'nowrap',
  cursor: 'pointer',
  userSelect: 'none',

  variants: {
    disabled: {
      true: {
        opacity: 0.5,
        cursor: 'not-allowed',
      },
      false: {},
    },
    size: {
      small: {
        ...SmallSansSizes,
      },
      large: {
        ...BodySansSizes,
      },
    },
  },
});

const MultiToggleInputContainer = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  alignItems: 'center',
  gap: '$6',
});

export type MultiToggleInputProps = Simplify<
  Omit<AriaRadioGroupProps, 'value' | 'onChange'> & {
    /**
     * Set if the component is disabled
     */
    disabled?: boolean;
    /**
     * Callback when selection changes
     */
    onChange?: (value: string) => void;
    /**
     * The options to display in the toggle
     */
    options: MultiToggleInputOptionProps[];
    /**
     * Set the size utilizing the controlSize API
     */
    size?: MultiToggleInputSizeProp;
    /**
     * The currently selected value
     */
    value?: string;
  }
>;

export function MultiToggleInputInner(
  { size = 'large', options, value, onChange, disabled = false, ...props }: MultiToggleInputProps,
  ref: React.Ref<HTMLDivElement>,
) {
  const localRef = useRef<HTMLDivElement>(null);
  const combinedRef = ref || localRef;
  const previousValueRef = useRef<string | undefined>(value);

  const state = useRadioGroupState({
    ...props,
    value,
    onChange,
  });

  const { radioGroupProps } = useRadioGroup(
    {
      ...props,
      value,
      onChange,
      isDisabled: disabled,
    },
    state,
  );

  const selectedIndex = options.findIndex((opt) => opt.value === state.selectedValue);
  const selectedOption = selectedIndex >= 0 ? options[selectedIndex] : null;

  const handleValueChange = (newValue: string) => {
    if (!disabled) {
      if (newValue === state.selectedValue) {
        onChange?.(previousValueRef.current || options[1].value);
      } else {
        previousValueRef.current = state.selectedValue || undefined;
        onChange?.(newValue);
      }
    }
  };

  return (
    <MultiToggleInputContainer>
      <MultiToggleInputEnds
        tabIndex={-1}
        as="button"
        context="body"
        disabled={disabled}
        size={size}
        variant={options[0] === selectedOption ? options[0].variant : 'neutral'}
        onClick={() => handleValueChange(options[0].value)}
      >
        {options[0].label}
      </MultiToggleInputEnds>
      <MultiToggleInputTrack {...radioGroupProps} ref={combinedRef} disabled={disabled} size={size}>
        {selectedOption && (
          <MultiToggleInputTab
            {...selectedOption}
            size={size}
            variant={selectedOption.variant}
            style={{
              left: `calc(${(100 / options.length) * selectedIndex}%)`,
            }}
          />
        )}
        {options.map((option) => (
          <MultiToggleInputOption
            key={option.value}
            {...option}
            disabled={disabled}
            size={size}
            variant={option.variant}
            data-selected={state.selectedValue === option.value}
            onClick={() => handleValueChange(option.value)}
          />
        ))}
      </MultiToggleInputTrack>
      <MultiToggleInputEnds
        tabIndex={-1}
        as="button"
        context="body"
        disabled={disabled}
        size={size}
        variant={
          options[options.length - 1] === selectedOption
            ? options[options.length - 1].variant
            : 'neutral'
        }
        onClick={() => handleValueChange(options[options.length - 1].value)}
      >
        {options[options.length - 1].label}
      </MultiToggleInputEnds>
    </MultiToggleInputContainer>
  );
}

export const MultiToggleInput = forwardRef(MultiToggleInputInner);
