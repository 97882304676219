import type { BadgeProps, IconName, ProductIconName } from '@meterup/atto';
import {
  Badge,
  colors,
  darkThemeSelector,
  FocusRing,
  Icon,
  selectors,
  SkeletonGroup,
  styled,
  Tooltip,
  transitions,
  useViewport,
} from '@meterup/atto';
import React from 'react';

import type { SigilProps } from '../Sigil';
import { useDockContext } from '../../../providers/DockProvider';
import { targetValues } from '../shared';
import { Sigil } from '../Sigil';

const ToolBadge = styled(Badge, {
  position: 'absolute',
  zIndex: 3,
  top: '-$4',
  right: '-$6',
  maxWidth: 'none',
});

const ToolIcon = styled(Icon, {
  color: '$$toolIconColor',
});

const ToolContainer = styled('div', FocusRing, {
  position: 'relative',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  backgroundColor: colors.transparent,
  strokeAll: colors.transparent,
  cursor: 'pointer',
  transition: transitions.control,

  $$toolIconColor: colors.iconNeutralLight,

  [darkThemeSelector]: {
    backgroundColor: colors.transparent,
    strokeAll: colors.transparent,

    $$toolIconColor: colors.iconNeutralDark,
  },

  '&[aria-expanded="true"]': {
    [`&, ${selectors.hover}`]: {
      backgroundColor: colors.bgBrandLight,
      strokeAll: colors.strokeBrandLight,

      $$toolIconColor: colors.iconBrandLight,

      [darkThemeSelector]: {
        backgroundColor: colors.bgBrandDark,
        strokeAll: colors.strokeBrandDark,

        $$toolIconColor: colors.iconBrandDark,
      },
    },
  },

  variants: {
    minimized: {
      true: {
        widthAll: '$28',
        heightAll: '$28',
        borderRadius: `$${targetValues.borderRadius['@desktop']}`,
      },
      false: {
        '@notDesktop': {
          widthAll: '$40',
          heightAll: '$40',
          borderRadius: `$${targetValues.borderRadius['@notDesktop']}`,
        },

        '@desktop': {
          widthAll: '$32',
          heightAll: '$32',
          borderRadius: `$${targetValues.borderRadius['@desktop']}`,
        },
      },
    },
    selected: {
      true: {
        [`&, ${selectors.hover}`]: {
          backgroundColor: colors.bgBrandLight,
          strokeAll: colors.strokeBrandLight,

          $$toolIconColor: colors.iconBrandLight,

          [darkThemeSelector]: {
            backgroundColor: colors.bgBrandDark,
            strokeAll: colors.strokeBrandDark,

            $$toolIconColor: colors.iconBrandDark,
          },
        },
      },
      false: {
        [selectors.hover]: {
          backgroundColor: colors.bgApplicationLight,
          strokeAll: colors.strokeNeutralLight,

          $$toolIconColor: colors.bodyNeutralLight,

          [darkThemeSelector]: {
            backgroundColor: colors.bgApplicationDark,
            strokeAll: colors.strokeNeutralDark,

            $$toolIconColor: colors.bodyNeutralDark,
          },
        },
      },
    },
  },
});

type ToolProps = {
  badge?: BadgeProps;
  fallback?: boolean;
  href?: string;
  icon?: IconName | ProductIconName;
  label: React.ReactNode;
  onClick?: () => void;
  selected?: boolean;
  sigil?: SigilProps;
  // Routing
  as?: React.ElementType;
  rel?: string;
  to?: string;
  target?: string;
  ref?: React.Ref<HTMLDivElement | null>;
};

export function Tool({
  badge,
  fallback,
  icon,
  label,
  onClick,
  selected = false,
  sigil,
  // Routing
  as: Component = 'div',
  ref: forwardedRef,
  rel,
  to,
  target,
  ...remaining
}: ToolProps) {
  const { breakpoint } = useViewport();
  const { minimized } = useDockContext();

  return fallback ? (
    <SkeletonGroup
      ref={forwardedRef}
      direction="row"
      padding={{ y: 4, x: 4 }}
      skeletons={[{ width: '100%', height: 24, radius: 6 }]}
    />
  ) : (
    <Tooltip side={minimized ? 'right' : 'bottom'} sideOffset={6} contents={label}>
      <ToolContainer
        ref={forwardedRef}
        as={Component}
        rel={rel}
        target={target}
        to={to}
        tabIndex={0}
        onClick={onClick}
        selected={selected}
        minimized={minimized}
        aria-label={label}
        {...remaining}
      >
        {badge && <ToolBadge ends="pill" size="small" variant="negative" {...badge} />}
        {(sigil || icon) && (
          <>
            {sigil && (
              <Sigil
                url={sigil.url}
                domain={sigil.domain}
                identity={sigil.identity}
                label={sigil.label}
                size={breakpoint !== 'desktop' ? 24 : 20}
                type={sigil.type}
              />
            )}
            {icon && <ToolIcon icon={icon as IconName} size={breakpoint !== 'desktop' ? 20 : 16} />}
          </>
        )}
      </ToolContainer>
    </Tooltip>
  );
}
