import { useDropzone } from 'react-dropzone';

import { Icon } from '../../assets/Icon/Icon';
import { Button } from '../../controls/Button/Button';
import { colors, darkThemeSelector, styled } from '../../stitches.config';
import { Small } from '../../text/Small';
import { ProgressBar } from '../ProgressBar/ProgressBar';
import { IconTooltip } from '../Tooltip/Tooltip';

export type ValidationError = {
  isValidationError: true;
  message: string;
};

export type FileUploaderError = Error | ValidationError;

function isValidationError(error: unknown): error is ValidationError {
  return error !== null && typeof error === 'object' && 'isValidationError' in error;
}

export type FileInfo = {
  id: string; // this is really just necessary to provide a unique key for use in lists
  uploadPercentage: number;
  fileObject: File;
  error: FileUploaderError | null;
};

export type ReactDropzoneProps = Exclude<Parameters<typeof useDropzone>[0], undefined>;

const DEFAULT_ALLOWED_ACCEPT = {
  image: {
    'image/jpeg': ['.jpg', '.jpeg'],
    'image/png': ['.png'],
    'image/gif': ['.gif'],
    'image/svg+xml': ['.svg'],
  },
  video: {
    'video/mp4': ['.mp4'],
    'video/quicktime': ['.mov'],
  },
  csv: {
    'text/csv': ['.csv'],
  },
  json: {
    'application/json': ['.json'],
  },
  pdf: {
    'application/pdf': ['.pdf'],
  },
};

export type DropzoneUploaderProps = {
  files: FileInfo[];
  onFileRemoved: (id: string) => any;
  onDrop: NonNullable<ReactDropzoneProps['onDrop']>;
  accept?: ReactDropzoneProps['accept'];
  onRetryUpload?: (id: string) => any;
  allowedTypes?: {
    images?: boolean;
    videos?: boolean;
    csv?: boolean;
    json?: boolean;
    pdf?: boolean;
  };
  maxFiles?: number;
  callToAction?: string;
};

function generateAccept(
  accept: ReactDropzoneProps['accept'],
  allowedTypes: DropzoneUploaderProps['allowedTypes'],
) {
  if (accept) return accept; // prioritize explicit accept prop
  if (!allowedTypes) return undefined;

  let generatedAccept: Exclude<ReactDropzoneProps['accept'], undefined> = {};
  if (allowedTypes.images) {
    generatedAccept = { ...generatedAccept, ...DEFAULT_ALLOWED_ACCEPT.image };
  }
  if (allowedTypes.videos) {
    generatedAccept = { ...generatedAccept, ...DEFAULT_ALLOWED_ACCEPT.video };
  }
  if (allowedTypes.csv) {
    generatedAccept = { ...generatedAccept, ...DEFAULT_ALLOWED_ACCEPT.csv };
  }
  if (allowedTypes.json) {
    generatedAccept = { ...generatedAccept, ...DEFAULT_ALLOWED_ACCEPT.json };
  }
  if (allowedTypes.pdf) {
    generatedAccept = { ...generatedAccept, ...DEFAULT_ALLOWED_ACCEPT.pdf };
  }
  return generatedAccept;
}

const DropzoneContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  borderRadius: '$8',

  variants: {
    empty: {
      true: {
        justifyContent: 'center',
        borderColor: colors.strokeNeutralLight,
        borderStyle: 'dashed',
        borderWidth: '$2',

        [darkThemeSelector]: {
          borderColor: colors.strokeNeutralDark,
        },
      },
      false: {
        strokeAll: colors.strokeNeutralLight,

        [darkThemeSelector]: {
          strokeAll: colors.strokeNeutralDark,
        },
      },
    },
  },
});

const DropzoneCTA = styled(Small, {
  width: '100%',
  truncate: true,
});

const DropzoneListItemFilename = styled(Small, {
  minWidth: 0,
  truncate: true,
});

const DropzoneListItem = styled('li', {
  minWidth: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '$8',
  width: '100%',
  padding: '$6 $12',
  overflow: 'hidden',
  strokeBottom: colors.strokeNeutralLight,

  [darkThemeSelector]: {
    strokeBottom: colors.strokeNeutralDark,
  },
});

const DropzoneList = styled('ul', {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',

  '&:last-child > li:last-child': {
    strokeBottom: colors.transparent,

    [darkThemeSelector]: {
      strokeBottom: colors.transparent,
    },
  },
});

const DropzoneAdd = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '$8',
  width: '100%',

  variants: {
    empty: {
      true: {
        padding: '$5 $6 $4',
      },
      false: {
        padding: '$6 $12',
      },
    },
  },
});

const DropzoneListItemControls = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
});

const DropzoneListItemError = styled('div', {
  display: 'flex',
  gap: '$8',
  alignItems: 'center',
  justifyContent: 'flex-end',
});

const DropzoneListItemProgress = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  minWidth: '$100',

  '@notDesktop': {
    minHeight: '$28',
  },

  '@desktop': {
    minHeight: '$24',
  },
});

function generateCTA(allowedTypes?: DropzoneUploaderProps['allowedTypes']): string {
  if (allowedTypes?.images && allowedTypes?.videos) {
    return 'Drop media here';
  }
  if (allowedTypes?.images) {
    return 'Drop images here';
  }
  if (allowedTypes?.videos) {
    return 'Drop videos here';
  }
  return 'Drop files here';
}

function generateEmptyCTA(allowedTypes?: DropzoneUploaderProps['allowedTypes']): string {
  if (allowedTypes?.images && allowedTypes?.videos) {
    return 'or drop media here';
  }
  if (allowedTypes?.images) {
    return 'or drop images here';
  }
  if (allowedTypes?.videos) {
    return 'or drop videos here';
  }
  return 'or drop files here';
}

function DropzoneListItemDelete(props: { onClick: () => void }) {
  return (
    <Button
      onClick={props.onClick}
      arrangement="hidden-label"
      icon="trash-can"
      ghost
      size="small"
      variant="destructive"
    >
      Delete
    </Button>
  );
}

/**
 * **🚨 You probably don't want to use this component directly!**
 *
 * This component is only handles the UI for uploading files.
 *
 * The UnifiedUploader component from `@meterup/common wraps` this _and_ handles the heavy lifting of uploading files.
 *
 * Unless you have a good reason, _use that instead._
 */
export function DropzoneUploader(props: DropzoneUploaderProps) {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: props.onDrop,
    accept: generateAccept(props.accept, props.allowedTypes),
    maxFiles: props.maxFiles,
    multiple: props.maxFiles !== 1,
  });

  const cta = props.callToAction ?? generateCTA(props.allowedTypes);

  const emptyCTA = props.callToAction ?? generateEmptyCTA(props.allowedTypes);

  let canUploadMore = true;
  if (props.maxFiles !== undefined && props.files.length >= props.maxFiles) {
    canUploadMore = false;
  }

  if (props.files.length > 0) {
    return (
      <DropzoneContainer empty={false}>
        <DropzoneList>
          {props.files.map((file) => {
            const errorMessage = isValidationError(file.error)
              ? file.error.message
              : 'Upload failed';
            return (
              <DropzoneListItem key={file.id}>
                <DropzoneListItemFilename>{file.fileObject.name}</DropzoneListItemFilename>
                {!file.error &&
                  (file.uploadPercentage === 100 ? (
                    <DropzoneListItemDelete onClick={() => props.onFileRemoved(file.id)} />
                  ) : (
                    <DropzoneListItemProgress>
                      <ProgressBar progressPercentage={file.uploadPercentage} />
                    </DropzoneListItemProgress>
                  ))}
                {file.error && (
                  <DropzoneListItemError>
                    <IconTooltip
                      contents={errorMessage}
                      icon="warning"
                      size={14}
                      variant="negative"
                    />
                    <DropzoneListItemControls>
                      {props.onRetryUpload && (
                        <Button
                          onClick={() => props.onRetryUpload?.(file.id)}
                          arrangement="hidden-label"
                          icon="reset"
                          ghost
                          size="small"
                          variant="secondary"
                        >
                          Retry
                        </Button>
                      )}
                      <DropzoneListItemDelete onClick={() => props.onFileRemoved(file.id)} />
                    </DropzoneListItemControls>
                  </DropzoneListItemError>
                )}
              </DropzoneListItem>
            );
          })}
        </DropzoneList>
        {canUploadMore && (
          <DropzoneAdd {...getRootProps()} active={isDragActive} empty={false}>
            <input {...getInputProps()} />
            <Icon icon="upload" size={14} variant="neutral" />
            <DropzoneCTA>{cta}</DropzoneCTA>
            <Button arrangement="leading-icon" icon="search" size="small" variant="secondary">
              Browse...
            </Button>
          </DropzoneAdd>
        )}
      </DropzoneContainer>
    );
  }

  return (
    <DropzoneContainer {...getRootProps()} active={isDragActive} empty>
      <input {...getInputProps()} />
      <DropzoneAdd empty>
        {/* <Icon icon="upload" size={14} variant="neutral" /> */}
        <Button arrangement="leading-icon" icon="search" size="small" variant="secondary">
          Browse...
        </Button>
        <DropzoneCTA>{emptyCTA}</DropzoneCTA>
      </DropzoneAdd>
    </DropzoneContainer>
  );
}
