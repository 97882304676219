import { merge } from 'lodash-es';
import { type ReactNode, useId } from 'react';

import type { AlignStackPresetProp } from '../../utilities/AlignStack/AlignStack';
import type { GutterProp } from '../../utilities/shared/Gutter';
import { colors, darkThemeSelector, styled } from '../../stitches.config';
import { AlignStack } from '../../utilities/AlignStack/AlignStack';
import { gutterCSS } from '../../utilities/shared/Gutter';

const MetadataListItemContainer = styled(AlignStack, 'li', {
  display: 'flex',
  flexDirection: 'row',
  padding: '$8 0',

  variants: {
    strokes: {
      'no-bottom': {
        strokeTopBottom: colors.strokeNeutralLight,

        [darkThemeSelector]: {
          strokeTopBottom: colors.strokeNeutralDark,
        },

        '&:last-child': {
          strokeTop: colors.strokeNeutralLight,

          [darkThemeSelector]: {
            strokeTop: colors.strokeNeutralDark,
          },
        },
      },
      'no-top': {
        strokeTopBottom: colors.strokeNeutralLight,

        [darkThemeSelector]: {
          strokeTopBottom: colors.strokeNeutralDark,
        },

        '&:first-child': {
          strokeBottom: colors.strokeNeutralLight,

          [darkThemeSelector]: {
            strokeBottom: colors.strokeNeutralDark,
          },
        },
      },
      'no-top-or-bottom': {
        strokeTopBottom: colors.strokeNeutralLight,

        [darkThemeSelector]: {
          strokeTopBottom: colors.strokeNeutralDark,
        },

        '&:first-child': {
          strokeBottom: colors.strokeNeutralLight,

          [darkThemeSelector]: {
            strokeBottom: colors.strokeNeutralDark,
          },
        },

        '&:last-child': {
          strokeTop: colors.strokeNeutralLight,

          [darkThemeSelector]: {
            strokeTop: colors.strokeNeutralDark,
          },
        },
      },
      none: {},
    },
  },
});

type MetadataListItemStrokesProp = 'no-bottom' | 'no-top' | 'no-top-or-bottom' | 'none';

type MetadataListItemProps = {
  /**
   * The end of the metadata list item.
   */
  end?: ReactNode;
  /**
   * The label of the metadata list item.
   */
  label: ReactNode;
  /**
   * The preset of the label.
   */
  preset?: AlignStackPresetProp;
  /**
   * The start of the metadata list item.
   */
  start?: ReactNode;
  /**
   * The strokes of the metadata list item.
   */
  strokes?: MetadataListItemStrokesProp;
};

function MetadataListItem({ end, label, preset = 'body', start, strokes }: MetadataListItemProps) {
  return (
    <MetadataListItemContainer strokes={strokes} gap={12} preset={preset} start={start} end={end}>
      {label}
    </MetadataListItemContainer>
  );
}

const MetadataListContainer = styled('ul', {
  display: 'flex',
  flexDirection: 'column',
});

type MetadataListProps = {
  /**
   * The gutter of the metadata list.
   */
  gutter?: GutterProp;
  /**
   * The list of items to display in the metadata list.
   */
  pairs: (Omit<MetadataListItemProps, 'preset' | 'strokes'> & { index: number })[];
  /**
   * The preset of the metadata list, this overrides any presets set on the items.
   */
  preset?: AlignStackPresetProp;
  /**
   * The strokes of the metadata list.
   */
  strokes?: MetadataListItemStrokesProp;
};

export function MetadataList({
  gutter = 'none',
  pairs,
  preset,
  strokes = 'no-top-or-bottom',
}: MetadataListProps) {
  const uuid = useId();
  return (
    <MetadataListContainer css={merge(gutterCSS({ context: 'content', gutter }))}>
      {pairs.map((pair) => (
        <MetadataListItem
          key={`${uuid}-${pair.label}-${pair.index}`}
          preset={preset}
          strokes={strokes}
          start={pair.start}
          label={pair.label}
          end={pair.end}
        />
      ))}
    </MetadataListContainer>
  );
}
