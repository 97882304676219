import { Body, Heading, Large, Link, Rule, styled, Subheading, Text, Title } from '@meterup/atto';
import React from 'react';
import BaseMarkdown from 'react-markdown';

const MarkdownContainer = styled(Text, {
  vStack: '$8',
  justifyContent: 'start',
  alignItems: 'start',
});

function H1({ children }: { children?: React.ReactNode }) {
  return <Title as="h1">{children}</Title>;
}

function H2({ children }: { children?: React.ReactNode }) {
  return <Heading as="h2">{children}</Heading>;
}

function H3({ children }: { children?: React.ReactNode }) {
  return <Large as="h3">{children}</Large>;
}

function H4({ children }: { children?: React.ReactNode }) {
  return <Subheading as="h4">{children}</Subheading>;
}

function Paragraph({ children }: { children?: React.ReactNode }) {
  return <Body>{children}</Body>;
}

function Strong({ children }: { children?: React.ReactNode }) {
  return <Text weight="bold">{children}</Text>;
}

function Italicize({ children }: { children?: React.ReactNode }) {
  return <Text italicize="italic">{children}</Text>;
}

function HorizontalRule() {
  return <Rule />;
}

const Ul = styled('ul', {
  paddingLeft: '$18',
  listStyleType: 'disc',
});

const Ol = styled('ol', {
  paddingLeft: '$18',
  listStyleType: 'decimal',
});

const LI = styled('li', Body);

export default function Markdown({ children }: { children: string }) {
  return (
    <MarkdownContainer>
      <BaseMarkdown
        components={{
          a: Link,
          h1: H1,
          h2: H2,
          h3: H3,
          h4: H4,
          ul: Ul,
          ol: Ol,
          li: LI,
          p: Paragraph,
          strong: Strong,
          em: Italicize,
          hr: HorizontalRule,
        }}
      >
        {children}
      </BaseMarkdown>
    </MarkdownContainer>
  );
}

export function MarkdownReferenceLink({ children = 'markdown' }: { children?: React.ReactNode }) {
  return (
    <Link href="https://commonmark.org/help/" target="_blank" rel="noreferrer">
      {children}
    </Link>
  );
}
