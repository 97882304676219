import React from 'react';

import { colors, darkThemeSelector, styled } from '../../stitches.config';

const ProgressBarFill = styled('div', {
  display: 'flex',
  height: '100%',
  background: colors.brand600,
  borderRadius: '99rem',
  transition: 'all 150ms ease-in-out',
});

const ProgressBarContainer = styled('div', {
  position: 'relative',
  display: 'flex',
  width: '100%',
  height: '$4',
  overflow: 'hidden',
  background: colors.brand300,
  borderRadius: '99rem',

  [darkThemeSelector]: {
    background: colors.brand800,
  },
});

export interface ProgressBarProps {
  progressPercentage: number;
}

export function ProgressBar({ progressPercentage }: ProgressBarProps) {
  return (
    <ProgressBarContainer>
      <ProgressBarFill style={{ width: `${progressPercentage}%` }} />
    </ProgressBarContainer>
  );
}
