import React from 'react';

const PagefilesImport0 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/drawers/autoVPN/AutoVPNGroupConfigEdit.page.tsx"));
const PagefilesImport1 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/drawers/autoVPN/AutoVPNGroupCreate.page.tsx"));
const PagefilesImport2 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/drawers/autoVPN/AutoVPNGroupMember.page.tsx"));
const PagefilesImport3 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/drawers/autoVPN/AutoVPNGroupRoutesAdd.page.tsx"));
const PagefilesImport4 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/drawers/autoVPN/AutoVPNMemberAdd.page.tsx"));
const PagefilesImport5 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/drawers/autoVPN/AutoVPNMemberEdit.page.tsx"));
const PagefilesImport6 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/drawers/connect/BulkAddLocations.page.tsx"));
const PagefilesImport7 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/drawers/network/hardware/SpareHardwareDeviceCreatePage.page.tsx"));
const PagefilesImport8 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/drawers/operator/alerting/CreateFleetwideAlert.page.tsx"));
const PagefilesImport9 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/drawers/operator/alerting/EditFleetwideAlert.page.tsx"));
const PagefilesImport10 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/drawers/operator/companies/CompanySummary.page.tsx"));
const PagefilesImport11 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/drawers/operator/firmwareBulkUpgrader/FirmwareBulkUpgradeDetails.page.tsx"));
const PagefilesImport12 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/drawers/operator/nos/NosFeatureDetail.page.tsx"));
const PagefilesImport13 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/drawers/operator/nos/NosVersionDetail.page.tsx"));
const PagefilesImport14 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/drawers/network/design/floor_plans/FloorPlanCreate.page.tsx"));
const PagefilesImport15 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/drawers/network/design/floor_plans/FloorPlanEdit.page.tsx"));
const PagefilesImport16 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/drawers/network/design/rack_elevation/AddRackDrawer.page.tsx"));
const PagefilesImport17 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/drawers/network/design/rack_elevation/AddRackNote.page.tsx"));
const PagefilesImport18 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/drawers/network/design/rack_elevation/AssignHardwareToNetwork.page.tsx"));
const PagefilesImport19 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/drawers/network/design/rack_elevation/AttachDeviceDrawer.page.tsx"));
const PagefilesImport20 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/drawers/network/design/rack_elevation/EditRackDrawer.page.tsx"));
const PagefilesImport21 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/drawers/network/design/rack_elevation/EditRackMount.page.tsx"));
const PagefilesImport22 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/drawers/network/design/rack_elevation/EditRackNote.page.tsx"));
const PagefilesImport23 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/drawers/network/firewall/dns_security/DNSSecurityRuleAdd.page.tsx"));
const PagefilesImport24 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/drawers/network/firewall/dns_security/DNSSecurityRuleDrawer.page.tsx"));
const PagefilesImport25 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/drawers/network/firewall/dns_security/DNSSecurityRuleEdit.page.tsx"));
const PagefilesImport26 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/drawers/network/firewall/dns_security/DNSSecurityRulesBulkEdit.page.tsx"));
const PagefilesImport27 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/drawers/network/firewall/nids/IDSEvent.page.tsx"));
const PagefilesImport28 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/drawers/network/firewall/nids/IDSEventFirewallRuleAdd.page.tsx"));
const PagefilesImport29 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/drawers/network/firewall/nids/IDSIPSConfig.page.tsx"));
const PagefilesImport30 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/drawers/network/firewall/nids/IDSRule.page.tsx"));
const PagefilesImport31 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/drawers/network/firewall/one_to_one_nat/CreateOneToOneNATRule.page.tsx"));
const PagefilesImport32 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/drawers/network/firewall/one_to_one_nat/EditOneToOneNATRule.page.tsx"));
const PagefilesImport33 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/drawers/network/firewall/port_forwarding/CreatePortForwardingRule.page.tsx"));
const PagefilesImport34 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/drawers/network/firewall/port_forwarding/EditPortForwardingRule.page.tsx"));
const PagefilesImport35 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/drawers/network/firewall/rules/FirewallRuleAdd.page.tsx"));
const PagefilesImport36 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/drawers/network/firewall/rules/FirewallRuleDrawer.page.tsx"));
const PagefilesImport37 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/drawers/network/firewall/rules/FirewallRuleEdit.page.tsx"));
const PagefilesImport38 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/drawers/network/hardware/access_points/ACSJobCreate.page.tsx"));
const PagefilesImport39 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/drawers/network/hardware/access_points/AccessPointBatchEdit.page.tsx"));
const PagefilesImport40 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/drawers/network/hardware/access_points/AccessPointClientsSummary.page.tsx"));
const PagefilesImport41 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/drawers/network/hardware/access_points/AccessPointCreate.page.tsx"));
const PagefilesImport42 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/drawers/network/hardware/access_points/AccessPointEditDrawer.page.tsx"));
const PagefilesImport43 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/drawers/network/hardware/access_points/AccessPointSummary.page.tsx"));
const PagefilesImport44 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/drawers/network/hardware/access_points/AccessPointsBulkCreate.page.tsx"));
const PagefilesImport45 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/drawers/network/hardware/access_points/AccessPointsBulkUploadCSV.page.tsx"));
const PagefilesImport46 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/drawers/network/hardware/observers/ObserverCreate.page.tsx"));
const PagefilesImport47 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/drawers/network/hardware/observers/ObserverDrawer.page.tsx"));
const PagefilesImport48 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/drawers/network/hardware/observers/ObserverEditDrawer.page.tsx"));
const PagefilesImport49 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/drawers/network/hardware/power_distrubution_units/OutletDetail.page.tsx"));
const PagefilesImport50 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/drawers/network/hardware/power_distrubution_units/PowerDistributionUnitCreate.page.tsx"));
const PagefilesImport51 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/drawers/network/hardware/power_distrubution_units/PowerDistributionUnitEdit.page.tsx"));
const PagefilesImport52 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/drawers/network/hardware/power_distrubution_units/PowerDistributionUnitSummary.page.tsx"));
const PagefilesImport53 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/drawers/network/hardware/security_appliances/HighAvailabilityEdit.page.tsx"));
const PagefilesImport54 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/drawers/network/hardware/security_appliances/SecurityApplianceCreateDrawer.page.tsx"));
const PagefilesImport55 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/drawers/network/hardware/security_appliances/SecurityApplianceEditDrawer.page.tsx"));
const PagefilesImport56 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/drawers/network/hardware/security_appliances/SecurityAppliancePortDetail.page.tsx"));
const PagefilesImport57 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/drawers/network/hardware/security_appliances/SecurityAppliancePortEditDrawer.page.tsx"));
const PagefilesImport58 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/drawers/network/hardware/security_appliances/SecurityApplianceProfileEdit.page.tsx"));
const PagefilesImport59 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/drawers/network/hardware/security_appliances/SecurityApplianceSummary.page.tsx"));
const PagefilesImport60 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/drawers/network/hardware/switches/PortBatchEdit.page.tsx"));
const PagefilesImport61 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/drawers/network/hardware/switches/SwitchBatchEdit.page.tsx"));
const PagefilesImport62 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/drawers/network/hardware/switches/SwitchBulkCreate.page.tsx"));
const PagefilesImport63 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/drawers/network/hardware/switches/SwitchCreate.page.tsx"));
const PagefilesImport64 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/drawers/network/hardware/switches/SwitchEdit.page.tsx"));
const PagefilesImport65 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/drawers/network/hardware/switches/SwitchPortDetail.page.tsx"));
const PagefilesImport66 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/drawers/network/hardware/switches/SwitchPortEdit.page.tsx"));
const PagefilesImport67 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/drawers/network/hardware/switches/SwitchProfileEdit.page.tsx"));
const PagefilesImport68 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/drawers/network/hardware/switches/SwitchSummary.page.tsx"));
const PagefilesImport69 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/drawers/network/insights/clients/ClientCPEAddDrawer.page.tsx"));
const PagefilesImport70 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/drawers/network/insights/clients/ClientCPEDrawer.page.tsx"));
const PagefilesImport71 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/drawers/network/insights/clients/ClientEditDrawer.page.tsx"));
const PagefilesImport72 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/drawers/network/insights/clients/ClientSummary.page.tsx"));
const PagefilesImport73 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/drawers/network/insights/events/EventLogSummary.page.tsx"));
const PagefilesImport74 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/drawers/network/insights/events/NetworkMutationSummary.page.tsx"));
const PagefilesImport75 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/drawers/network/insights/host_monitoring/HostMonitoringTargetAdd.page.tsx"));
const PagefilesImport76 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/drawers/network/insights/host_monitoring/HostMonitoringTargetEdit.page.tsx"));
const PagefilesImport77 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/drawers/network/network_wide/isps/ISPAddDrawer.page.tsx"));
const PagefilesImport78 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/drawers/network/network_wide/isps/ISPDrawer.page.tsx"));
const PagefilesImport79 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/drawers/network/network_wide/isps/ISPEditDrawer.page.tsx"));
const PagefilesImport80 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/drawers/network/network_wide/isps/ISPEventDrawer.page.tsx"));
const PagefilesImport81 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/drawers/network/network_wide/isps/ISPMoveDrawer.page.tsx"));
const PagefilesImport82 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/drawers/network/network_wide/radius_profiles/RADIUSProfileAdd.page.tsx"));
const PagefilesImport83 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/drawers/network/network_wide/radius_profiles/RADIUSProfileDrawer.page.tsx"));
const PagefilesImport84 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/drawers/network/network_wide/radius_profiles/RADIUSProfileEdit.page.tsx"));
const PagefilesImport85 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/drawers/network/network_wide/rate_limiting/RateLimitingRule.page.tsx"));
const PagefilesImport86 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/drawers/network/network_wide/rate_limiting/RateLimitingRuleAdd.page.tsx"));
const PagefilesImport87 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/drawers/network/network_wide/rate_limiting/RateLimitingRuleEdit.page.tsx"));
const PagefilesImport88 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/drawers/network/network_wide/routing/IPForwardingRuleCreate.page.tsx"));
const PagefilesImport89 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/drawers/network/network_wide/routing/IPForwardingRuleEdit.page.tsx"));
const PagefilesImport90 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/drawers/network/network_wide/routing/PolicyRoutingRuleCreate.page.tsx"));
const PagefilesImport91 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/drawers/network/network_wide/routing/PolicyRoutingRuleEdit.page.tsx"));
const PagefilesImport92 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/drawers/network/network_wide/routing/StaticRouteCreate.page.tsx"));
const PagefilesImport93 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/drawers/network/network_wide/routing/StaticRouteEdit.page.tsx"));
const PagefilesImport94 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/drawers/network/network_wide/vlans/VLANDrawer.page.tsx"));
const PagefilesImport95 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/drawers/network/network_wide/vlans/VLANEdit.page.tsx"));
const PagefilesImport96 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/drawers/network/network_wide/vlans/VLANsAdd.page.tsx"));
const PagefilesImport97 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/drawers/network/operators/upgrades/NetworkUpgradesConfig.page.tsx"));
const PagefilesImport98 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/drawers/network/secure_tunnels/ipsec/AddIPSecTunnelDrawer.page.tsx"));
const PagefilesImport99 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/drawers/network/secure_tunnels/ipsec/EditIPSecTunnelDrawer.page.tsx"));
const PagefilesImport100 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/drawers/network/secure_tunnels/ipsec/IPSecTunnelDrawer.page.tsx"));
const PagefilesImport101 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/drawers/network/secure_tunnels/vpn/Client.page.tsx"));
const PagefilesImport102 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/drawers/network/secure_tunnels/vpn/CreateClient.page.tsx"));
const PagefilesImport103 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/drawers/network/secure_tunnels/vpn/CreateServer.page.tsx"));
const PagefilesImport104 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/drawers/network/secure_tunnels/vpn/UpdateServer.page.tsx"));
const PagefilesImport105 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/drawers/network/wireless/hotspot20/Hotspot20Add.page.tsx"));
const PagefilesImport106 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/drawers/network/wireless/hotspot20/Hotspot20Edit.page.tsx"));
const PagefilesImport107 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/drawers/network/wireless/hotspot20/Hotspot20NAIRealmEdit.page.tsx"));
const PagefilesImport108 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/drawers/network/wireless/radio_profiles/RadioProfileCreate.page.tsx"));
const PagefilesImport109 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/drawers/network/wireless/radio_profiles/RadioProfileEdit.page.tsx"));
const PagefilesImport110 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/drawers/network/wireless/ssids/SSIDCreate.page.tsx"));
const PagefilesImport111 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/drawers/network/wireless/ssids/SSIDDrawer.page.tsx"));
const PagefilesImport112 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/drawers/network/wireless/ssids/SSIDEdit.page.tsx"));
const PagefilesImport113 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/drawers/network/wireless/wids/NetworkThreatAllowListEntryCreate.page.tsx"));
const PagefilesImport114 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/drawers/network/wireless/wids/NetworkThreatAllowListEntryEdit.page.tsx"));
const PagefilesImport115 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/drawers/operator/nos/upgradeGroups/CreateNOSUpgradeGroup.page.tsx"));
const PagefilesImport116 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/drawers/operator/nos/upgradeGroups/EditNOSUpgradeGroup.page.tsx"));
const PagefilesImport117 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/drawers/operator/nos/upgrades/CreateNOSScheduledUpgrade.page.tsx"));
const PagefilesImport118 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/drawers/settings/org/users/AddUser.page.tsx"));
const PagefilesImport119 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/drawers/settings/org/users/EditUser.page.tsx"));
const PagefilesImport120 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/drawers/settings/org/users/EditUserRoles.page.tsx"));
const PagefilesImport121 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/drawers/settings/org/users/UserDrawer.page.tsx"));
const PagefilesImport122 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/drawers/network/insights/clients/connection_events/ConnectionEventDetail.page.tsx"));
const PagefilesImport123 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/drawers/network/insights/clients/vulnerabilities/CPEDrawer.page.tsx"));
const PagefilesImport124 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/drawers/network/insights/clients/vulnerabilities/CVEDrawer.page.tsx"));
const PagefilesImport125 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/drawers/network/wireless/ssids/psks/PSKCreate.page.tsx"));
const PagefilesImport126 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/drawers/network/wireless/ssids/psks/PSKEdit.page.tsx"));
const PagefilesImport127 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/drawers/settings/network/notifications/alerts/AlertEdit.page.tsx"));
const PagefilesImport128 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/drawers/settings/network/notifications/alerts/AlertsAdd.page.tsx"));
const PagefilesImport129 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/drawers/settings/network/notifications/reports/ReportEdit.page.tsx"));
const PagefilesImport130 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/drawers/settings/network/notifications/reports/ReportsAdd.page.tsx"));
const PagefilesImport131 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/drawers/settings/org/integrations/receivers/ReceiverEdit.page.tsx"));
const PagefilesImport132 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/drawers/settings/org/integrations/receivers/ReceiversAdd.page.tsx"));
const PagefilesImport133 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/drawers/settings/org/integrations/services/ServiceEdit.page.tsx"));
const PagefilesImport134 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/drawers/settings/org/integrations/services/ServicesAdd.page.tsx"));
const PagefilesImport135 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/drawers/settings/org/integrations/webhooks/WebhookEdit.page.tsx"));
const PagefilesImport136 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/drawers/settings/org/integrations/webhooks/WebhooksAdd.page.tsx"));

const routes = [
{path: "/operator/companies/:slug",element: React.createElement(PagefilesImport10)},
{path: "/operator/firmware-bulk-upgrader/upgrades/:uuid",element: React.createElement(PagefilesImport11)},
{path: "/operator/fleetwide-alert-management/:uuid/edit",element: React.createElement(PagefilesImport9)},
{path: "/operator/fleetwide-alert-management/add",element: React.createElement(PagefilesImport8)},
{path: "/operator/nos-upgrade-groups/:networkUUID/:uuid",element: React.createElement(PagefilesImport116)},
{path: "/operator/nos-upgrade-groups/new/:networkUUID",element: React.createElement(PagefilesImport115)},
{path: "/operator/nos-upgrades/new/:networkUUID",element: React.createElement(PagefilesImport117)},
{path: "/operator/nos/features/:uuid",element: React.createElement(PagefilesImport12)},
{path: "/operator/nos/versions/:nosVersionID",element: React.createElement(PagefilesImport13)},
{path: "/org/:companyName/auto-vpn/groups/:groupUUID/members/:memberUUID",element: React.createElement(PagefilesImport2)},
{path: "/org/:companyName/auto-vpn/groups/add",element: React.createElement(PagefilesImport1)},
{path: "/org/:companyName/connect/locations/add",element: React.createElement(PagefilesImport6)},
{path: "/org/:companyName/network/:networkSlug/connection-events/:timestamp",element: React.createElement(PagefilesImport122)},
{path: "/org/:companyName/network/:networkSlug/design/floor-plans/:floorPlanUUID",element: React.createElement(PagefilesImport15)},
{path: "/org/:companyName/network/:networkSlug/design/floor-plans/create",element: React.createElement(PagefilesImport14)},
{path: "/org/:companyName/network/:networkSlug/devices/:uuid",element: React.createElement(PagefilesImport40)},
{path: "/org/:companyName/network/:networkSlug/firewall-rules/port-forwarding/:ruleUUID",element: React.createElement(PagefilesImport34)},
{path: "/org/:companyName/network/:networkSlug/firewall/acls/:ruleUUID",element: React.createElement(PagefilesImport36)},
{path: "/org/:companyName/network/:networkSlug/firewall/acls/:ruleUUID/edit",element: React.createElement(PagefilesImport37)},
{path: "/org/:companyName/network/:networkSlug/firewall/acls/add",element: React.createElement(PagefilesImport35)},
{path: "/org/:companyName/network/:networkSlug/firewall/dns-security/:ruleUUID",element: React.createElement(PagefilesImport24)},
{path: "/org/:companyName/network/:networkSlug/firewall/dns-security/:ruleUUID/edit",element: React.createElement(PagefilesImport25)},
{path: "/org/:companyName/network/:networkSlug/firewall/dns-security/add",element: React.createElement(PagefilesImport23)},
{path: "/org/:companyName/network/:networkSlug/firewall/dns-security/bulk-edit/:uuids",element: React.createElement(PagefilesImport26)},
{path: "/org/:companyName/network/:networkSlug/firewall/nids/configure",element: React.createElement(PagefilesImport29)},
{path: "/org/:companyName/network/:networkSlug/firewall/nids/ids/:virtualDeviceUUID/:observedAt",element: React.createElement(PagefilesImport27)},
{path: "/org/:companyName/network/:networkSlug/firewall/nids/ids/:virtualDeviceUUID/:observedAt/add",element: React.createElement(PagefilesImport28)},
{path: "/org/:companyName/network/:networkSlug/firewall/nids/ids/rule/:sid",element: React.createElement(PagefilesImport30)},
{path: "/org/:companyName/network/:networkSlug/firewall/one-to-one-nat/:ruleUUID",element: React.createElement(PagefilesImport32)},
{path: "/org/:companyName/network/:networkSlug/firewall/one-to-one-nat/create",element: React.createElement(PagefilesImport31)},
{path: "/org/:companyName/network/:networkSlug/hardware/access-points/:id/edit/:tab",element: React.createElement(PagefilesImport42)},
{path: "/org/:companyName/network/:networkSlug/hardware/access-points/:uuid/:tab",element: React.createElement(PagefilesImport43)},
{path: "/org/:companyName/network/:networkSlug/hardware/access-points/acs/create",element: React.createElement(PagefilesImport38)},
{path: "/org/:companyName/network/:networkSlug/hardware/access-points/batch-edit/:accessPointUUIDs",element: React.createElement(PagefilesImport39)},
{path: "/org/:companyName/network/:networkSlug/hardware/access-points/bulk-upload",element: React.createElement(PagefilesImport45)},
{path: "/org/:companyName/network/:networkSlug/hardware/access-points/create",element: React.createElement(PagefilesImport41)},
{path: "/org/:companyName/network/:networkSlug/hardware/access-points/create-bulk",element: React.createElement(PagefilesImport44)},
{path: "/org/:companyName/network/:networkSlug/hardware/observers/:id/edit/:tab",element: React.createElement(PagefilesImport48)},
{path: "/org/:companyName/network/:networkSlug/hardware/observers/:uuid",element: React.createElement(PagefilesImport47)},
{path: "/org/:companyName/network/:networkSlug/hardware/observers/create",element: React.createElement(PagefilesImport46)},
{path: "/org/:companyName/network/:networkSlug/hardware/power-distribution-units/:uuid",element: React.createElement(PagefilesImport52)},
{path: "/org/:companyName/network/:networkSlug/hardware/power-distribution-units/:uuid/edit/:tab",element: React.createElement(PagefilesImport51)},
{path: "/org/:companyName/network/:networkSlug/hardware/power-distribution-units/:uuid/outlets/:outletInterfaceUUID",element: React.createElement(PagefilesImport49)},
{path: "/org/:companyName/network/:networkSlug/hardware/power-distribution-units/create",element: React.createElement(PagefilesImport50)},
{path: "/org/:companyName/network/:networkSlug/hardware/security-appliances/:uuid/profile",element: React.createElement(PagefilesImport58)},
{path: "/org/:companyName/network/:networkSlug/hardware/security-appliances/:uuid/summary",element: React.createElement(PagefilesImport59)},
{path: "/org/:companyName/network/:networkSlug/hardware/security-appliances/:virtualDeviceUUID/edit/:tab",element: React.createElement(PagefilesImport55)},
{path: "/org/:companyName/network/:networkSlug/hardware/security-appliances/:virtualDeviceUUID/ports/:phyInterfaceUUID",element: React.createElement(PagefilesImport56)},
{path: "/org/:companyName/network/:networkSlug/hardware/security-appliances/:virtualDeviceUUID/ports/:phyInterfaceUUID/edit",element: React.createElement(PagefilesImport57)},
{path: "/org/:companyName/network/:networkSlug/hardware/security-appliances/add",element: React.createElement(PagefilesImport54)},
{path: "/org/:companyName/network/:networkSlug/hardware/security-appliances/high-availability",element: React.createElement(PagefilesImport53)},
{path: "/org/:companyName/network/:networkSlug/hardware/switches/:id/edit/:tab",element: React.createElement(PagefilesImport64)},
{path: "/org/:companyName/network/:networkSlug/hardware/switches/:id/edit/profile",element: React.createElement(PagefilesImport67)},
{path: "/org/:companyName/network/:networkSlug/hardware/switches/:uuid",element: React.createElement(PagefilesImport68)},
{path: "/org/:companyName/network/:networkSlug/hardware/switches/:virtualDeviceUUID/ports/:phyInterfaceUUID",element: React.createElement(PagefilesImport65)},
{path: "/org/:companyName/network/:networkSlug/hardware/switches/:virtualDeviceUUID/ports/:phyInterfaceUUID/edit",element: React.createElement(PagefilesImport66)},
{path: "/org/:companyName/network/:networkSlug/hardware/switches/:virtualDeviceUUID/ports/batch-edit/:phyInterfaceUUIDs",element: React.createElement(PagefilesImport60)},
{path: "/org/:companyName/network/:networkSlug/hardware/switches/add",element: React.createElement(PagefilesImport63)},
{path: "/org/:companyName/network/:networkSlug/hardware/switches/batch-edit/:switchUUIDs",element: React.createElement(PagefilesImport61)},
{path: "/org/:companyName/network/:networkSlug/hardware/switches/create-bulk",element: React.createElement(PagefilesImport62)},
{path: "/org/:companyName/network/:networkSlug/insights/clients/:macAddress",element: React.createElement(PagefilesImport72)},
{path: "/org/:companyName/network/:networkSlug/insights/clients/:macAddress/cpes/:cpeUUID",element: React.createElement(PagefilesImport70)},
{path: "/org/:companyName/network/:networkSlug/insights/clients/:macAddress/edit",element: React.createElement(PagefilesImport71)},
{path: "/org/:companyName/network/:networkSlug/insights/clients/:macAddresses/cpes/add",element: React.createElement(PagefilesImport69)},
{path: "/org/:companyName/network/:networkSlug/insights/clients/cpes/:cpeUUID",element: React.createElement(PagefilesImport123)},
{path: "/org/:companyName/network/:networkSlug/insights/clients/cves/:cveID",element: React.createElement(PagefilesImport124)},
{path: "/org/:companyName/network/:networkSlug/insights/events/:uuid",element: React.createElement(PagefilesImport73)},
{path: "/org/:companyName/network/:networkSlug/insights/host-monitoring/:targetUUID",element: React.createElement(PagefilesImport76)},
{path: "/org/:companyName/network/:networkSlug/insights/host-monitoring/add",element: React.createElement(PagefilesImport75)},
{path: "/org/:companyName/network/:networkSlug/logs/mutations/:mutationAuditLogEntryUUID",element: React.createElement(PagefilesImport74)},
{path: "/org/:companyName/network/:networkSlug/network-wide/isps/:isp",element: React.createElement(PagefilesImport78)},
{path: "/org/:companyName/network/:networkSlug/network-wide/isps/:isp/edit",element: React.createElement(PagefilesImport79)},
{path: "/org/:companyName/network/:networkSlug/network-wide/isps/:uuid/events/:eventUUID",element: React.createElement(PagefilesImport80)},
{path: "/org/:companyName/network/:networkSlug/network-wide/isps/add",element: React.createElement(PagefilesImport77)},
{path: "/org/:companyName/network/:networkSlug/network-wide/isps/move",element: React.createElement(PagefilesImport81)},
{path: "/org/:companyName/network/:networkSlug/network-wide/rate-limiting/:ruleUUID",element: React.createElement(PagefilesImport85)},
{path: "/org/:companyName/network/:networkSlug/network-wide/rate-limiting/:ruleUUID/edit",element: React.createElement(PagefilesImport87)},
{path: "/org/:companyName/network/:networkSlug/network-wide/rate-limiting/add",element: React.createElement(PagefilesImport86)},
{path: "/org/:companyName/network/:networkSlug/network-wide/vlans/:uuid",element: React.createElement(PagefilesImport94)},
{path: "/org/:companyName/network/:networkSlug/network-wide/vlans/:uuid/edit",element: React.createElement(PagefilesImport95)},
{path: "/org/:companyName/network/:networkSlug/network-wide/vlans/add",element: React.createElement(PagefilesImport96)},
{path: "/org/:companyName/network/:networkSlug/port-forwarding/create",element: React.createElement(PagefilesImport33)},
{path: "/org/:companyName/network/:networkSlug/rack-elevations/:elevationUUID/attached-device/:deviceUUID",element: React.createElement(PagefilesImport21)},
{path: "/org/:companyName/network/:networkSlug/rack-elevations/:elevationUUID/attached-device/add",element: React.createElement(PagefilesImport19)},
{path: "/org/:companyName/network/:networkSlug/rack-elevations/:elevationUUID/notes/:noteIndexStart",element: React.createElement(PagefilesImport22)},
{path: "/org/:companyName/network/:networkSlug/rack-elevations/:elevationUUID/notes/add",element: React.createElement(PagefilesImport17)},
{path: "/org/:companyName/network/:networkSlug/rack-elevations/:uuid/edit",element: React.createElement(PagefilesImport20)},
{path: "/org/:companyName/network/:networkSlug/rack-elevations/add",element: React.createElement(PagefilesImport16)},
{path: "/org/:companyName/network/:networkSlug/rack-elevations/attach-hardware",element: React.createElement(PagefilesImport18)},
{path: "/org/:companyName/network/:networkSlug/radius-profiles/:uuid",element: React.createElement(PagefilesImport83)},
{path: "/org/:companyName/network/:networkSlug/radius-profiles/:uuid/edit",element: React.createElement(PagefilesImport84)},
{path: "/org/:companyName/network/:networkSlug/radius-profiles/add",element: React.createElement(PagefilesImport82)},
{path: "/org/:companyName/network/:networkSlug/routing/ip-forwarding/:ruleUUID",element: React.createElement(PagefilesImport89)},
{path: "/org/:companyName/network/:networkSlug/routing/ip-forwarding/create",element: React.createElement(PagefilesImport88)},
{path: "/org/:companyName/network/:networkSlug/routing/policy-routing/:ruleUUID",element: React.createElement(PagefilesImport91)},
{path: "/org/:companyName/network/:networkSlug/routing/policy-routing/create",element: React.createElement(PagefilesImport90)},
{path: "/org/:companyName/network/:networkSlug/routing/static-route/:routeUUID",element: React.createElement(PagefilesImport93)},
{path: "/org/:companyName/network/:networkSlug/routing/static-route/create",element: React.createElement(PagefilesImport92)},
{path: "/org/:companyName/network/:networkSlug/secure-tunnels/ipsec/:IPSecUUID",element: React.createElement(PagefilesImport100)},
{path: "/org/:companyName/network/:networkSlug/secure-tunnels/ipsec/:IPSecUUID/edit",element: React.createElement(PagefilesImport99)},
{path: "/org/:companyName/network/:networkSlug/secure-tunnels/ipsec/add",element: React.createElement(PagefilesImport98)},
{path: "/org/:companyName/network/:networkSlug/secure-tunnels/vpn/:serverUUID",element: React.createElement(PagefilesImport104)},
{path: "/org/:companyName/network/:networkSlug/secure-tunnels/vpn/add",element: React.createElement(PagefilesImport103)},
{path: "/org/:companyName/network/:networkSlug/spare-hardware/assign",element: React.createElement(PagefilesImport7)},
{path: "/org/:companyName/network/:networkSlug/vpn/:serverUUID/clients/:clientUUID/:tab",element: React.createElement(PagefilesImport101)},
{path: "/org/:companyName/network/:networkSlug/vpn/:serverUUID/clients/create",element: React.createElement(PagefilesImport102)},
{path: "/org/:companyName/network/:networkSlug/wireless/hotspot2.0/:uuid/edit",element: React.createElement(PagefilesImport106)},
{path: "/org/:companyName/network/:networkSlug/wireless/hotspot2.0/:uuid/nai-realm/edit",element: React.createElement(PagefilesImport107)},
{path: "/org/:companyName/network/:networkSlug/wireless/hotspot2.0/add",element: React.createElement(PagefilesImport105)},
{path: "/org/:companyName/network/:networkSlug/wireless/radio-profiles/:uuid",element: React.createElement(PagefilesImport109)},
{path: "/org/:companyName/network/:networkSlug/wireless/radio-profiles/create",element: React.createElement(PagefilesImport108)},
{path: "/org/:companyName/network/:networkSlug/wireless/ssids/:ssidUUID/psks/:uuid/edit/:tab",element: React.createElement(PagefilesImport126)},
{path: "/org/:companyName/network/:networkSlug/wireless/ssids/:ssidUUID/psks/add",element: React.createElement(PagefilesImport125)},
{path: "/org/:companyName/network/:networkSlug/wireless/ssids/:uuid",element: React.createElement(PagefilesImport111)},
{path: "/org/:companyName/network/:networkSlug/wireless/ssids/:uuid/edit",element: React.createElement(PagefilesImport112)},
{path: "/org/:companyName/network/:networkSlug/wireless/ssids/create",element: React.createElement(PagefilesImport110)},
{path: "/org/:companyName/network/:networkSlug/wireless/wids/allowlist/:uuid",element: React.createElement(PagefilesImport114)},
{path: "/org/:companyName/network/:networkSlug/wireless/wids/allowlist/add",element: React.createElement(PagefilesImport113)},
{path: "/org/:companyName/network/:networkUUID/operators/nos-upgrades/config",element: React.createElement(PagefilesImport97)},
{path: "/org/:companyName/network/auto-vpn/groups/:groupUUID/edit",element: React.createElement(PagefilesImport0)},
{path: "/org/:companyName/network/auto-vpn/groups/:groupUUID/members/:memberUUID/edit",element: React.createElement(PagefilesImport5)},
{path: "/org/:companyName/network/auto-vpn/groups/:groupUUID/members/add",element: React.createElement(PagefilesImport4)},
{path: "/org/:companyName/network/auto-vpn/groups/:groupUUID/routes",element: React.createElement(PagefilesImport3)},
{path: "/org/:companyName/settings/integrations/receivers/:uuid/edit",element: React.createElement(PagefilesImport131)},
{path: "/org/:companyName/settings/integrations/services/service/edit",element: React.createElement(PagefilesImport133)},
{path: "/org/:companyName/settings/integrations/webhooks/:uuid/edit",element: React.createElement(PagefilesImport135)},
{path: "/org/:companyName/settings/network/:networkSlug/notifications/alert/:uuid/edit",element: React.createElement(PagefilesImport127)},
{path: "/org/:companyName/settings/network/:networkSlug/notifications/alerts/add",element: React.createElement(PagefilesImport128)},
{path: "/org/:companyName/settings/network/:networkSlug/notifications/reports/add",element: React.createElement(PagefilesImport130)},
{path: "/org/:companyName/settings/network/:networkSlug/notifications/reports/edit",element: React.createElement(PagefilesImport129)},
{path: "/org/:companyName/settings/org/integrations/receivers/add",element: React.createElement(PagefilesImport132)},
{path: "/org/:companyName/settings/org/integrations/services/add",element: React.createElement(PagefilesImport134)},
{path: "/org/:companyName/settings/org/integrations/webhooks/add",element: React.createElement(PagefilesImport136)},
{path: "/org/:companyName/settings/org/users/:uuid",element: React.createElement(PagefilesImport121)},
{path: "/org/:companyName/settings/org/users/:uuid/edit",element: React.createElement(PagefilesImport119)},
{path: "/org/:companyName/settings/org/users/:uuid/roles",element: React.createElement(PagefilesImport120)},
{path: "/org/:companyName/settings/org/users/add",element: React.createElement(PagefilesImport118)},
];

export default routes;