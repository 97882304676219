import { fade, palette } from '../../common/colors';
import { darkThemeSelector, styled } from '../../stitches.config';
import { Text } from '../../text/Text';

const CodeContent = styled(Text, {
  position: 'relative',
  zIndex: 1,
  color: fade(palette.tokenLabelBrandLight, 0.9),
  fontFamily: '$mono',

  [darkThemeSelector]: {
    color: fade(palette.tokenLabelBrandDark, 0.9),
  },
});

const CodeContainer = styled('span', {
  position: 'relative',
  padding: '0 $2',

  '&::after': {
    content: '',
    position: 'absolute',
    zIndex: 0,
    display: 'flex',
    inset: '-$1 -$2',
    backgroundColor: fade(palette.tokenBgBrandLight, 0.3),
    strokeAll: fade(palette.tokenStrokeBrandLight, 0.3),
    borderRadius: '$6',

    [darkThemeSelector]: {
      backgroundColor: fade(palette.tokenBgBrandDark, 0.5),
      strokeAll: fade(palette.tokenStrokeBrandDark, 0.5),
    },
  },
});

export function Code({ children }: { children: React.ReactNode }) {
  return (
    <CodeContainer>
      <CodeContent>{children}</CodeContent>
    </CodeContainer>
  );
}
