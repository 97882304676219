import { isEmpty } from 'lodash-es';
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useDebounce } from 'use-debounce';

import { useSearchParamsStable } from '../hooks/useSearchParams';

function utf8ToBase64(str: string) {
  return window.btoa(encodeURIComponent(str));
}

function base64ToUTF8(str: string) {
  return decodeURIComponent(window.atob(str));
}

type Serializable = string | number | boolean | object | null;

type Updater<T> = T | ((prev: T) => T);

interface SearchParamsStateContextValue {
  keys: { [key: string]: Serializable };
  setKey<T extends Serializable>(key: string, updater: Updater<T | null>): void;
  getNextSearchParams<T extends Serializable>(
    key: string,
    updater: Updater<T | null>,
  ): URLSearchParams;
}

const SearchParamsStateContext = createContext<SearchParamsStateContextValue>(null as any);

const SEARCH_PARAMS_STATE_KEY = 's';

function serializeState(state: { [p: string]: Serializable }) {
  return !isEmpty(state) ? utf8ToBase64(JSON.stringify(state)) : null;
}

export function getSearchParamsStateParamsObject(state: Record<string, Serializable>) {
  return {
    [SEARCH_PARAMS_STATE_KEY]: serializeState(state),
  };
}

function deserializeState(serializedVal: string) {
  return JSON.parse(base64ToUTF8(serializedVal));
}

function getNextState<T>(
  key: string,
  prev: { [p: string]: Serializable },
  updater: Updater<T | null>,
) {
  const nextValue = updater instanceof Function ? updater(prev[key] as any) : updater;

  if (nextValue === null) {
    const { [key]: skip, ...rest } = prev;
    return rest;
  }

  return {
    ...prev,
    [key]: nextValue,
  };
}

type SearchParamsState = { [key: string]: Serializable };

export function SearchParamsStateProvider({ children }: { children?: React.ReactNode }) {
  const [searchParams, setSearchParams] = useSearchParamsStable();

  const [state, innerSetState] = useState<SearchParamsState>(() => {
    const serializedVal = searchParams.get(SEARCH_PARAMS_STATE_KEY);

    if (serializedVal) {
      return deserializeState(serializedVal);
    }

    return {};
  });

  const [debouncedState, { flush }] = useDebounce(state, 200);

  const setState = useCallback<React.Dispatch<React.SetStateAction<SearchParamsState>>>(
    (updater: Updater<SearchParamsState>) => {
      innerSetState((prevState) => {
        const newState = typeof updater === 'function' ? updater(prevState) : updater;

        setSearchParams(
          (newSearchParams) => {
            const serializedState = serializeState(newState);
            if (serializedState) {
              newSearchParams.set(SEARCH_PARAMS_STATE_KEY, serializedState);
            } else {
              newSearchParams.delete(SEARCH_PARAMS_STATE_KEY);
            }

            return newSearchParams;
          },
          { replace: true },
        );
        return newState;
      });
    },
    [setSearchParams],
  );

  useEffect(() => {
    const serializedVal = searchParams.get(SEARCH_PARAMS_STATE_KEY);

    if (serializedVal === null) {
      innerSetState({});
    } else if (serializedVal !== serializeState(debouncedState)) {
      innerSetState(deserializeState(serializedVal));
    }

    flush();
    // Ignoring debouncedState changes here intentionally
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  const setKey = useCallback(
    <T extends Serializable>(key: string, updater: Updater<T | null>) => {
      setState((prev) => getNextState(key, prev, updater));
    },
    [setState],
  );

  const value = useMemo(
    () => ({
      keys: state,
      getNextSearchParams<T extends Serializable>(key: string, updater: Updater<T | null>) {
        const nextState = serializeState(getNextState(key, state, updater));
        const nextParams = new URLSearchParams(searchParams);

        if (nextState) {
          nextParams.set(SEARCH_PARAMS_STATE_KEY, nextState);
        } else {
          nextParams.delete(SEARCH_PARAMS_STATE_KEY);
        }

        return nextParams;
      },
      setKey,
    }),
    [searchParams, state, setKey],
  );

  return (
    <SearchParamsStateContext.Provider value={value}>{children}</SearchParamsStateContext.Provider>
  );
}

export function useSearchParamsStateContext() {
  return useContext(SearchParamsStateContext);
}

export function useSearchParamsState<T extends Serializable>(
  key: string,
): [T | undefined, (updater: Updater<T> | null) => void, (updater: Updater<T>) => URLSearchParams];
export function useSearchParamsState<T extends Serializable>(
  key: string,
  defaultValue: T,
): [T, (updater: Updater<T> | null) => void, (updater: Updater<T>) => URLSearchParams];
export function useSearchParamsState<T extends Serializable>(
  key: string,
  defaultValue?: T,
): [T | undefined, (updater: Updater<T> | null) => void, (updater: Updater<T>) => URLSearchParams];
export function useSearchParamsState<T extends Serializable>(
  key: string,
  defaultValue?: T,
): [T | undefined, (updater: Updater<T> | null) => void, (updater: Updater<T>) => URLSearchParams] {
  const ctx = useContext(SearchParamsStateContext);

  if (!ctx) {
    throw new Error('useSearchParamsState must be used within SearchParamsStateProvider');
  }

  const { setKey } = ctx;

  const setValue = useCallback(
    (updater: Updater<T> | null) => {
      if (updater === defaultValue || updater === null) {
        return setKey(key, null);
      }

      return setKey(key, updater as Updater<T | null>);
    },
    [setKey, defaultValue, key],
  );

  const getNextSearchParams = useCallback(
    (updater: Updater<T>) => {
      if (updater === defaultValue) {
        return ctx.getNextSearchParams(key, null);
      }

      return ctx.getNextSearchParams(key, updater as Updater<T | null>);
    },
    [ctx, defaultValue, key],
  );

  return useMemo(
    () => [(ctx.keys[key] ?? defaultValue) as T | undefined, setValue, getNextSearchParams],
    [ctx.keys, defaultValue, getNextSearchParams, key, setValue],
  );
}
