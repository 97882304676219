import type { CellContext, ColumnMeta, Row, StringOrTemplateHeader } from '@tanstack/react-table';
import type { ReactElement, ReactNode } from 'react';
import {
  type AccessorFnColumnDef,
  type ColumnSizingColumnDef,
  type DisplayColumnDef,
  type FiltersColumnDef,
  type SortingColumnDef,
  type VisibilityColumnDef,
} from '@tanstack/table-core';
import React from 'react';

export type FilterVariant = 'select' | 'multi-select' | 'text' | 'number-range';
export type FilterValue = string | string[] | [number | null, number | null];
export type FilterComponentProps = {
  id: string;
  value: FilterValue;
  handleFiltersChange: (id: string, value: any) => void;
  onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => void;
};
export type FilterComponentFunction = (filterProps: FilterComponentProps) => ReactElement;
export type FilterOptions = { label: ReactNode; value: string }[];
export interface SharedColumnDef<RowType, ValueType>
  extends VisibilityColumnDef,
    SortingColumnDef<RowType>,
    FiltersColumnDef<RowType>,
    ColumnSizingColumnDef {
  id: string;
  header?: StringOrTemplateHeader<RowType, ValueType>;
  meta?: ColumnMeta<RowType, ValueType>;
  enableGlobalFilter?: boolean;
  filter?: FilterComponentFunction;
  filterLabel?: string;
  filterOptions?: FilterOptions;
  filterVariant?: FilterVariant;
}

export type RowWithHelpers<T> = Pick<
  Row<T>,
  | 'getIsSelected'
  | 'getCanSelect'
  | 'getCanMultiSelect'
  | 'getToggleSelectedHandler'
  | 'toggleSelected'
  | 'index'
  | 'id'
> &
  T;

export function createColumnBuilder<RowType>() {
  return {
    data: <ValueType extends string | number | null | undefined, ID extends string>(
      accessorFn: (row: RowType) => ValueType,
      {
        cell,
        ...attrs
      }: SharedColumnDef<RowType, ValueType> & {
        id: ID;
        cell?: (props: { value: ValueType; row: RowType }) => React.ReactNode | null;
      },
    ): Omit<AccessorFnColumnDef<RowType, ValueType>, 'id'> & { id: ID } => ({
      accessorFn,
      ...attrs,
      ...(cell
        ? {
            cell: (props) =>
              React.createElement(cell, {
                row: props.row.original,
                value: props.getValue(),
              }),
          }
        : undefined),
    }),
    display: <ValueType extends string, ID extends string>({
      cell,
      ...attrs
    }: SharedColumnDef<RowType, ValueType> & {
      id: ID;
      cell?: (props: {
        row: RowWithHelpers<RowType>;
        table: CellContext<RowType, ValueType>['table'];
      }) => React.ReactNode | null;
    }): DisplayColumnDef<RowType, ValueType> & { id: ID } => ({
      ...attrs,
      ...(cell
        ? {
            cell: (props) =>
              React.createElement(cell, {
                table: props.table,
                row: {
                  ...props.row.original,
                  getIsSelected: props.row.getIsSelected,
                  getCanSelect: props.row.getCanSelect,
                  getCanMultiSelect: props.row.getCanMultiSelect,
                  getToggleSelectedHandler: props.row.getToggleSelectedHandler,
                  toggleSelected: props.row.toggleSelected,
                  index: props.row.index,
                  id: props.row.id,
                },
              }),
          }
        : undefined),
    }),
  };
}
