import type { CSS } from '../../stitches.config';
import type { SizingScale } from './sizes';

export type HeightTypes = SizingScale | string;
export type HeightProps = { height?: HeightTypes };

export const heightCSS = (height?: HeightTypes): CSS => {
  if (
    height === 'auto' ||
    height === 'fit-content' ||
    height === 'max-content' ||
    height === 'min-content'
  ) {
    return { height };
  }

  if (height === 'full') {
    return { height: '100%' };
  }

  if (height === 'screen') {
    return { height: '100dvh' };
  }

  if (typeof height === 'string' && (height.includes('vh') || height.includes('dvh'))) {
    return {
      height: `${height}`,
    };
  }

  if (height != null) {
    return {
      height: `$${height}`,
    };
  }

  return {};
};
